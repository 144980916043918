import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Container from 'components/shared/Container';
import { Row, Col } from 'styled-bootstrap-grid';
import SEO from 'components/shared/SEO';
import HeaderMini from 'components/Header/HeaderMini';
import { Text } from 'components/shared/Typography';
import LazyImage from 'components/shared/LazyImage';

const Wrapper = styled.section`
  padding: 0 0 115px 0;

  @media screen and (max-width: 992px) {
    padding: 0 0 40px 0;
  }
`;

const TextHeading = styled(Text)`
  margin: 0 0 15px 0;
  text-transform: uppercase;
  font-weight: 600;
`;

const Box = styled.div`
  padding: 0 40px 0 0;

  @media screen and (max-width: 992px) {
    padding: 0 20px 0 0;
    margin: 0 0 20px 0;
  }
`;

const Banner = styled(LazyImage)`
  margin: 0 0 105px 0;
  max-height: 90vh;
  overflow: hidden;

  @media screen and (max-width: 992px) {
    margin: 0 0 50px 0;
    width: calc(100vw - 40px);
    height: calc(100vw - 40px);
  }
`;

const CompanyPage = ({ data }) => {
  const PAGE = data.wpPage.PageAbout;

  return (
    <>
      <SEO title="O firmie" />
      <HeaderMini
        spacing
        maxWidth="480px"
        subheading={PAGE.aboutBannerSubheading}
        heading={PAGE.aboutBannerHeading}
        text={PAGE.aboutBannerText}
      />
      <Wrapper>
        <Container>
          <Banner
            cover
            src={
              PAGE.aboutBannerImage.localFile.childImageSharp.gatsbyImageData
            }
            alt=""
          />
          <Row>
            <Col lg={6}>
              <TextHeading>Misja i cele firmy</TextHeading>
            </Col>
          </Row>
          <Row>
            {PAGE.aboutDescContentLeft && (
              <Col lg={6}>
                <Text>
                  <Box>{PAGE.aboutDescContentLeft}</Box>
                </Text>
              </Col>
            )}
            {PAGE.aboutDescContentRight && (
              <Col lg={6}>
                <Text>
                  <Box>{PAGE.aboutDescContentRight}</Box>
                </Text>
              </Col>
            )}
          </Row>
        </Container>
      </Wrapper>
    </>
  );
};

CompanyPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryPageCompany {
    wpPage(slug: { eq: "o-firmie" }) {
      PageAbout {
        aboutBannerImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [WEBP]
              )
            }
          }
        }
        aboutBannerSubheading
        aboutBannerHeading
        aboutBannerText
        aboutDescHeading
        aboutDescContentLeft
        aboutDescContentRight
      }
    }
  }
`;

export default CompanyPage;
